<template>
    <div>
        <div v-click-outside="closeCost" :class="{active:productInEdit.id}" class="price_popover_item"
             v-bind:style="{ top: productInEdit.top + 'px', left: productInEdit.left + 'px' }">
            <div class="price_popover_item_inner">
                <div class="price_popover_title">
                    <label for="currency-field-cost"></label>
                    <input id="currency-field-cost" v-model="productInEdit.cost" class="price_popover_input"
                           data-type="currency"
                           placeholder="₽ " type="text">
                </div>
                <div class="controls_buttons">
                    <div class="popover_status" @click="setCost">
                        <span class="fm-check"></span>
                    </div>
                    <div class="close_popover close_input_popover" @click="closeCost">
                        <span class="fm-x"></span>
                    </div>
                </div>
            </div>
        </div>


        <div :class="{'no_items': (!data.data || data.data.length === 0) && !loading}"
             class="expenses-wrapper dashboard-wrapper">
            <div v-if="loading" class="loading-wrapper">
                <div class="loading-wrapper__inner">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <div v-if="(!data.data || data.data.length === 0) && !loading" class="empty_banner">
                <div class="empty_banner_search">
                    <span class="fm-carbon_search"></span>
                </div>
                <h4 class="empty_banner_title">Ничего не найдено</h4>
                <p class="empty_banner_description">Попробуйте поменять параметры фильтра и попробовать снова</p>
            </div>


            <!-- Because of floating table!! -->
            <template v-if="section === 'products'">
                <template v-if="query.groupBy === 'nm_id' || query.groupBy === 'brand'">
                    <template v-for="element in data.data">
                        <template v-for="item in element.products">
                            <button v-show="false" :id="'cost-edit-button-'+item.product.id"
                                    @click="costEdit(item.product.id)">costEdit({{ item.product.id }})
                            </button>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <template v-for="item in data.data">
                        <button v-show="false" :id="'cost-edit-button-'+item.id" @click="costEdit(item.id)">
                            costEdit({{ item.id }})
                        </button>
                    </template>
                </template>
            </template>


            <table v-show="data.data && data.data.length > 0 && !loading" id="dashboardTable"
                   class="table table-condensed table-hover table-fw-widget dashboard-table"
                   data-responsive-table>
                <thead>
                <tr>
                    <th style="width: 20%;min-width: 100px;">Товары</th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'quantity'}" class="sortable-header nowrap"
                        data-breakpoints="sm md lg"
                        style="width: 5%; min-width: 50px;"
                        @click="sortByColumn('quantity')">
                        Продано
                        <button v-show="this.query.orderBy === 'quantity'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'refunds'}" class="sortable-header nowrap"
                        data-breakpoints="xs sm md lg"
                        style="width: 5%; min-width: 50px;" @click="sortByColumn('refunds')">
                        Возвращено
                        <button v-show="this.query.orderBy === 'refunds'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'salesAmount'}" class="sortable-header"
                        data-breakpoints="sm"
                        style="width: 5%; min-width: 50px;" @click="sortByColumn('salesAmount')">
                        Продажи
                        <button v-show="this.query.orderBy === 'salesAmount'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'refundsAmount'}" class="sortable-header"
                        data-breakpoints="xs sm md lg"
                        style="width: 5%; min-width: 50px;"
                        @click="sortByColumn('refundsAmount')">
                        Возвраты
                        <button v-show="this.query.orderBy === 'refundsAmount'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'deduction'}" class="sortable-header nowrap"
                        data-breakpoints="xs sm md lg"
                        style="width: 5%; min-width: 50px;" @click="sortByColumn('deduction')">
                        Удержания
                        <button v-show="this.query.orderBy === 'deduction'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <!-- <th style="width: 5%; min-width: 50px;" @click="sortByColumn('logisticCost')" class="sortable-header"
                        :class="{'reorder_outer': this.query.orderBy === 'logisticCost'}" data-breakpoints="xs sm">
                        Доставка
                        <button v-show="this.query.orderBy === 'logisticCost'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button"></button>
                    </th> -->
                    <!-- <th style="width: 5%; min-width: 50px;" @click="sortByColumn('commission')" class="sortable-header"
                        :class="{'reorder_outer': this.query.orderBy === 'commission'}" data-breakpoints="xs sm md">
                        Комиссия
                        <button v-show="this.query.orderBy === 'commission'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button"></button>
                    </th> -->
                    <!-- <th style="width: 5%; min-width: 50px;" class="sortable-header nowrap"
                        :class="{'reorder_outer': this.query.orderBy === 'grossProfit'}" data-breakpoints="xs sm md">

                        <button v-show="inaccurate" class="tooltip_outer" type="button" :data-tippy-content="__('hints.dashboard.inaccurate')">
                            <span class="fm-icon-attention"></span>
                        </button>
                        <span @click="sortByColumn('grossProfit')">Вал. прибыль
                            <button v-show="this.query.orderBy === 'grossProfit'" :class="{active: this.query.desc === 0}" type="button" class="reorder_button"></button>
                        </span>
                    </th> -->
                    <!-- <th style="width: 5%; min-width: 50px;" @click="sortByColumn('expenses')" class="sortable-header"
                        :class="{'reorder_outer': this.query.orderBy === 'expenses'}" data-breakpoints="xs sm md lg">
                        Расходы
                        <button v-show="this.query.orderBy === 'expenses'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button"></button>
                    </th> -->
                    <th :class="{'reorder_outer': this.query.orderBy === 'profit'}" class="sortable-header nowrap"
                        data-breakpoints="xs" style="width: 5%; min-width: 50px;">

                        <button v-show="inaccurate" :data-tippy-content="__('hints.dashboard.wb.warn.profit_inaccurate_no_costs')"
                                class="tooltip_outer"
                                type="button">
                            <span class="fm-icon-attention"></span>
                        </button>
                        <span @click="sortByColumn('profit')">Прибыль
                            <button v-show="this.query.orderBy === 'profit'" :class="{active: this.query.desc === 0}"
                                    class="reorder_button" type="button"></button>
                        </span>

                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'profitPerItem'}"
                        class="sortable-header nowrap"
                        data-breakpoints="xs sm md"
                        style="width: 5%; min-width: 50px;"
                        @click="sortByColumn('profitPerItem')">
                        Прибыль на&nbsp;ед.
                        <button v-show="this.query.orderBy === 'profitPerItem'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button">
                        </button>
                    </th>
                    <!-- <th style="width: 5%; min-width: 50px;" @click="sortByColumn('wbPay')" class="sortable-header nowrap"
                        :class="{'reorder_outer': this.query.orderBy === 'wbPay'}" data-breakpoints="xs sm md">
                        Выплаты WB
                        <button v-show="this.query.orderBy === 'wbPay'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button"></button>
                    </th> -->
                    <th :class="{'reorder_outer': this.query.orderBy === 'margin'}" class="sortable-header"
                        data-breakpoints="xs sm"
                        style="width: 5%; min-width: 50px;" @click="sortByColumn('margin')">
                        Маржа
                        <button v-show="this.query.orderBy === 'margin'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'roi'}" class="sortable-header"
                        data-breakpoints="xs sm md"
                        style="width: 5%; min-width: 50px;" @click="sortByColumn('roi')">
                        ROI
                        <button v-show="this.query.orderBy === 'roi'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <th :class="{'reorder_outer': this.query.orderBy === 'redemption'}" class="sortable-header"
                        data-breakpoints="xs sm md"
                        style="width: 5%; min-width: 50px;" @click="sortByColumn('redemption')">
                        Выкупаемость
                        <button v-show="this.query.orderBy === 'redemption'" :class="{active: this.query.desc === 0}"
                                class="reorder_button"
                                type="button"></button>
                    </th>
                    <!-- <th style="width: 5%; min-width: 50px;">
                        Страны
                    </th> -->
                    <th style="width: 5%; max-width: 60px;"><span class="hide-xs">Инфо</span></th>
                </tr>
                </thead>
                <tbody v-if="(query.groupBy === 'nm_id' || query.groupBy === 'brand') && !loading">
                <template v-for="item in data.data">
                    <tr v-if="query.groupBy != 'brand' && (item.products && item.products.length === 1)"
                        class="products-ungrouped">
                        <DashboardProductsTableRow v-bind:disableProductPrice="forAll" v-bind:item="item.products[0]"
                                                   @setSummary="setSummary"/>
                    </tr>
                    <template v-else-if="item.products">
                        <tr class="products-grouped" data-toggle-products>
                            <DashboardProductsTableRowGroup v-bind:groupBy="query.groupBy" v-bind:item="item"
                                                            @setSummary="setSummary"/>
                        </tr>
                        <tr v-for="productItem in item.products"
                            :data-order_id="item.products[0].product.nmId" class="table-accordion__content hidden">
                            <DashboardProductsTableRowGroupChild v-bind:disableProductPrice="forAll"
                                                                 v-bind:groupBy="query.groupBy"
                                                                 v-bind:item="productItem"
                                                                 @setSummary="setSummary"/>
                        </tr>
                    </template>
                </template>
                </tbody>
                <tbody v-else-if="!loading">
                <tr v-for="item in data.data">
                    <DashboardProductsTableRow v-bind:disableProductPrice="forAll" v-bind:item="item"
                                               @setSummary="setSummary"/>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-show="data.data && data.data.length > 0 && !loading" class="table-last-section">
            <!--
            <a :href="exportLink" class="download_button btn-default btn-regular" :class="{disabled: isDownloading}" @click.prevent="downloadReport(exportLink)">
                Скачать таблицу (.xls)
                <span class="btn-loader" v-show="isDownloading">
                    <span class="btn-loader__inner">
                        <span class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                    </span>
                </span>
            </a>
            -->

            <Pagination
                :current-page="data.current_page"
                :links="data.links"
                :next="data.next_page_url"
                :previous="data.prev_page_url"
                :total-pages="data.last_page"
                @page-selected="pageChanged"
            />
        </div>

    </div>
</template>

<script>

import Pagination from '@/Components/Common/Pagination'
import DashboardMoreButton from '@/Components/Dashboard/DashboardMoreButton'
import ProductsCostModal from '@/Components/Products/ProductsCostModal'
import { Link } from '@inertiajs/inertia-vue3'
import { pickBy } from 'lodash-es'
import DashboardProductsTableRow from '@/Components/Dashboard/DashboardProductsTableRow'
import DashboardProductsTableRowGroup from '@/Components/Dashboard/DashboardProductsTableRowGroup'
import DashboardProductsTableRowGroupChild from '@/Components/Dashboard/DashboardProductsTableRowGroupChild'

export default {
    props: [
        'currentRange',
        'query',
        'externalDataLoading',
        'section',
        'forAll'
    ],
    components: {
        Pagination,
        DashboardMoreButton,
        ProductsCostModal,
        DashboardProductsTableRow,
        DashboardProductsTableRowGroup,
        DashboardProductsTableRowGroupChild,
        Link,
    },
    emits: ['reload', 'dashboardCostEdit', 'paginationCustomParams', 'loadingProducts', 'queryChanged', 'setSummary'],
    methods: {
        setSummary(summary, product, isBrandSelected = false) {
            console.log('2',isBrandSelected);
            this.$emit('setSummary', summary, product, isBrandSelected);
        },
        downloadReport(url) {
            if (this.isDownloading)
                return false;
            let self = this;
            this.isDownloading = true;
            axios.get(url, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'report.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    self.isDownloading = false;
                }).catch(console.error)
        },
        // reloadTable(){
        //     this.$emit('reload', { fullReload: true });
        // },
        toggleTableRow(document, event) {
            let $element = $(event.target);
            let id = $element.data('order');

            if ($element.attr('aria-selected') == 'false') {
                $('[data-order_id="' + id + '"]').removeClass('hidden');
                $element.attr('aria-selected', 'true');
            } else {
                $('[data-order_id="' + id + '"]').addClass('hidden');
                $element.attr('aria-selected', 'false');
            }
        },
        costEdit(id) {
            this.$emit('dashboardCostEdit', id);
        },
        methodThatForcesUpdate() {
            // ...
            this.$forceUpdate();  // Notice we have to use a $ here
            // ...
        },
        getData() {
            let self = this;
            this.loading = true;
            this.$emit('loadingProducts', this.loading);

            $('#dashboardTable').hide();

            this.cancelTokenSource = axios.CancelToken.source();
            this.pendingRequest = true;
            this.inaccurate = false;
            let query = this.query;
            let url = '/dashboard/products';
            if (this.forAll) {
                url = '/dashboard/products-for-all';
            }

            query.products = Array.isArray(query.products) ? query.products.join(',') : query.products;
            axios.get(url, {
                cancelToken: this.cancelTokenSource.token,
                params: pickBy(query)
            }).then((result) => {
                this.data = result.data;
                this.$emit('paginationCustomParams', result.data.custom_params);
                this.data.data.forEach((item) => {
                    if (item.summary.inaccurate)
                        this.inaccurate = true;
                });

                this.setExportLink()
                setTimeout(function () {
                    self.responsiveTable();
                }, 200)

            });
        },
        pageChanged(page) {
            this.query.page = page;
            this.getData();
        },
        page(link) {
            let page = '';

            if (typeof link === 'string' || link instanceof String) {
                page = link;
            } else {
                page = link.url;
            }

            this.query.page = page.split("=")[1];
        },
        closeCost() {
            this.productInEdit.id = 0;
            this.productInEdit.cost = 0;
        },
        setCost(productId, newCost) {
            if (typeof productId !== 'number')
                productId = Number(this.productInEdit.id);
            if (!newCost)
                newCost = this.productInEdit.cost;


            newCost = String(newCost).replace(/[^\d\.]*/g, '');
            axios.post('/product/cost', {id: productId, cost: newCost, cost_type: 0})
                .then(() => {
                    this.$emit('reload', {fullReload: true});
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        $('#currency-field' + productId).css('border-color', '#ff0000');
                    }
                });
        },

        sortByColumn: function (column) {

            if (this.query.orderBy === column)
                this.query.desc = this.query.desc === 1 ? 0 : 1;
            else
                this.query.desc = 1;

            this.query.orderBy = column;
            this.getData();
        },
        currencyInput: function () {
            let self = this;

            $(document).on('keyup', 'input[data-type="currency"]', function () {
                self.formatCurrency($(this));
            });

            $(document).on('blur', 'input[data-type="currency"]', function () {
                self.formatCurrency($(this), 'blur');
            });
        },
        formatNumber: function (n) {
            // format number 1000000 to 1,234,567
            return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        formatCurrency: function (input, blur) {
            let input_val = input.val();

            // don't validate empty input
            if (input_val === "") {
                return;
            }

            // original length
            var original_len = input_val.length;

            // initial caret position
            var caret_pos = input.prop("selectionStart");

            // check for decimal
            if (input_val.indexOf(".") >= 0) {

                // get position of first decimal
                // this prevents multiple decimals from
                // being entered
                var decimal_pos = input_val.indexOf(".");

                // split number by decimal point
                var left_side = input_val.substring(0, decimal_pos);
                var right_side = input_val.substring(decimal_pos);

                // add commas to left side of number
                left_side = this.formatNumber(left_side);

                // validate right side
                right_side = this.formatNumber(right_side);

                // On blur make sure 2 numbers after decimal
                if (blur === "blur") {
                    right_side += "00";
                }

                // Limit decimal to only 2 digits
                right_side = right_side.substring(0, 2);

                // join number by .
                input_val = "₽ " + left_side + "." + right_side;

            } else {
                // no decimal entered
                // add commas to number
                // remove all non-digits
                input_val = this.formatNumber(input_val);
                input_val = "₽ " + input_val;

                // final formatting
                if (blur === "blur") {
                    input_val += ".00";
                }
            }

            // send updated string to input
            input.val(input_val);
            // put caret back in the right position
            var updated_len = input_val.length;
            caret_pos = updated_len - original_len + caret_pos;
            input[0].setSelectionRange(caret_pos, caret_pos);
        },
        popovers: function () {
            let self = this;

            let clickHandler = 'click';

            if ('ontouchstart' in document.documentElement) {
                clickHandler = 'touchend';
            }
            $('[data-toggle="tooltip"]').tooltip();
            //inputs popovers
            let popoversForm = $('#dashboardTable'),
                tooltipParent = popoversForm.find('.price_popover'),
                toggleSwitch = $('.open_price_popover');

            $(document).on(clickHandler, '.open_price_popover', function (e) {
                let $this = $(this);
                let productId = $this.attr('data-id');
                $('.modal').modal('hide')
                $('#cost-edit-button-' + productId).click();
                return false;

                e.preventDefault();
                setTimeout(function () {
                    /**
                     * set timeout because click-outside event fires together and closes!!
                     */
                    self.productInEdit.top = $this.offset().top + 20;
                    self.productInEdit.left = $this.offset().left;
                    self.productInEdit.id = $this.data('id');
                    self.productInEdit.cost = $this.data('cost');
                }, 50);
            });


            //table  More popovers

            let moreTooltipParent = popoversForm.find('.more_popover_outer'),
                moreToggleSwitch = moreTooltipParent.find('.open_more_popover');

            moreToggleSwitch.on(clickHandler, function (evt) {
                evt.preventDefault();
                let mSelf = $(this);
                moreTooltipParent.not(mSelf.parent()).removeClass('active');
                if (!mSelf.parent().hasClass('active')) {
                    mSelf.parent().addClass('active');
                    return false;
                } else {
                    mSelf.parent().removeClass('active');
                    return false;
                }
            });

        },
        jsInit() {
            let self = this;
            let clickHandler = 'click';

            if ('ontouchstart' in document.documentElement) {
                clickHandler = 'touchend';
            }

            $('[data-toggle="tooltip"]').tooltip();
            //inputs popovers
            let popoversForm = $('#dashboardTable'),
                tooltipParent = popoversForm.find('.price_popover'),
                toggleSwitch = tooltipParent.find('.open_price_popover');

            // toggleSwitch.on(clickHandler, function(e){
            //     e.preventDefault();
            //     let self = $(this);
            //     tooltipParent.not(self.parent()).removeClass('active');
            //     if(!self.parent().hasClass('active')){
            //         self.parent().addClass('active');
            //         self.parent().find('input').focus();
            //     } else {
            //         self.parent().removeClass('active');
            //     }
            // });


            $(document).on(clickHandler, '[data-toggle-table-row]', function (e) {
                e.preventDefault();
                let $element = $(this);
                let id = $(this).data('order');
                if ($element.attr('aria-selected') == 'false') {
                    $('[data-order_id="' + id + '"]').removeClass('hidden');
                    $element.attr('aria-selected', 'true');
                    $element.closest('[data-toggle-products]').addClass('active');
                } else {
                    $('[data-order_id="' + id + '"]').addClass('hidden');
                    $element.attr('aria-selected', 'false');
                    $element.closest('[data-toggle-products]').removeClass('active');
                }
            })
            $(document).on(clickHandler, '.open_popover', null, function (e) {
                e.preventDefault();
                $(this).parent().addClass('active');
            });

            // $(document).on(clickHandler, '.close_popover' , null, function () {
            //     $(this).closest('.popover_block').removeClass('active');
            // });

            // $(document).on(clickHandler, function (e) {
            //     let popoverDropdown = $('.price_popover_item.active');
            //     if ($(e.target).is(popoverDropdown) === false && popoverDropdown.has(e.target).length === 0) {
            //         self.productInEdit.id = 0;
            //         self.productInEdit.cost = 0;
            //         //$(".popover_block").removeClass("active");
            //     }
            // });
            // $(document).on(clickHandler, '.close_input_popover' , null, function () {
            //     $(this).closest('.price_popover').removeClass('active');
            // });

            $("body").on(clickHandler, function (e) {
                tooltipParent.each(function () {
                    //console.log(tooltipParent.length);
                    if (!tooltipParent.is(e.target) && tooltipParent.has(e.target).length === 0) {
                        tooltipParent.removeClass('active')
                    }
                });
            });
            $(document).on(clickHandler, '.close_more_popover', null, function () {
                $(this).closest('.more_popover_outer').removeClass('active');
            });

            // $(document).on(clickHandler, '.popover_status' , null, (event) => {
            //     console.log('.popover_status Clicked')
            //     event.stopPropagation();
            //     const productId = $(event.currentTarget).data('id');
            //     this.setCost(productId, $('#currency-field' + productId).val());
            // });

            $(document).on(clickHandler, '.loading_button', null, function () {
                $(this).children('.ring_loader').show();
                setTimeout(() => {
                    $(this).children('.ring_loader').fadeOut();
                }, 3000);
            });

            // $(".has-children").on(clickHandler,function () {

            $(document).on(clickHandler, function (e) {
                let moreDropdown = $('.more_popover_outer');
                if ($(e.target).is(moreDropdown) === false && moreDropdown.has(e.target).length === 0) {
                    moreDropdown.removeClass("active");
                }
            });
            console.log('DashboardProductsTableJsMounted');
        },
        tableHeader() {
            let dashboardTableHead = $('#dashboardTable thead');

            function fixingHead() {
                if (dashboardTableHead.length) {
                    let $dashboardForm = $('#dashboardTable');
                    if ($dashboardForm.length && $dashboardForm.offset().top - 50 < $(document).scrollTop()) {
                        dashboardTableHead.addClass('fixedTop');
                    } else {
                        dashboardTableHead.removeClass('fixedTop');
                    }
                }

            }

            $(window).on('scroll', function () {
                fixingHead();
            });

            $(window).on('load resize', function () {
                fixingHead();
            });
        },
        tippyTooltips() {
            tippy('[data-tippy-content]', {
                trigger: 'mouseenter focus',
                placement: 'bottom',
                arrow: false
            });
        },
        responsiveTable() {
            let responsiveOrdersTable = $('#dashboardTable');

            if (!this.data.data.length) {
                responsiveOrdersTable.hide();
                this.loading = false;
                this.$emit('loadingProducts', this.loading);
                this.pendingRequest = false;
                return false;
            }


            this.currencyInput();
            this.popovers();

            this.loading = false;
            this.$emit('loadingProducts', this.loading);
            this.pendingRequest = false;

            this.tippyTooltips();
            // responsiveOrdersTable.footable({
            //     "showToggle": true
            // }, () => {
            //     this.currencyInput();
            //     this.popovers();
            //
            //     this.loading = false;
            //     this.$emit('loadingProducts', this.loading);
            //     this.pendingRequest = false;
            //
            //     this.tippyTooltips();
            // });
            // responsiveOrdersTable.trigger('footable_initialize');

            setTimeout(() => {
                $('#tableDataLoader').fadeOut();
            }, 5000);
        },
        setExportLink() {
            this.exportLink = '/dashboard/export?dateFrom='
                + this.currentRange.dateFrom
                + '&dateTo=' + this.currentRange.dateTo;

            if (this.query.products) {
                this.exportLink = this.exportLink + '&products=' + this.query.products
            }

            if (this.query.orderBy) {
                this.exportLink = this.exportLink + '&orderBy=' + this.query.orderBy

                if (this.query.desc)
                    this.exportLink = this.exportLink + '&desc=' + this.query.desc
            }
        },
    },
    data() {
        return {
            isDownloading: false,
            testData: [
                ['', 'Ford', 'Volvo', 'Toyota', 'Honda'],
                ['2016', 10, 11, 12, 13],
                ['2017', 20, 11, 14, 13],
                ['2018', 30, 15, 12, 13]
            ],
            data: {
                data: [],
                links: []
            },
            inaccurate: false,
            products: [],
            exportLink: null,
            sort: {
                orderBy: 'quantity',
                desc: 1
            },
            loading: true,
            cancelTokenSource: null,
            pendingRequest: false,
            productInEdit: {
                id: 0,
                cost: 0,
                top: 0,
                left: 0
            }
        };
    },
    mounted() {
        this.sort = {
            orderBy: this.query.orderBy,
            desc: this.query.desc,
            products: this.query.products
        };

        console.log('DashboardProductsTableMounted');

        if (!this.externalDataLoading) {
            this.getData();
        }

        this.tableHeader();
        this.currencyInput();

        if (typeof window.isDashboardProductsTableJsMounted == 'undefined') {
            this.jsInit();
            window.isDashboardProductsTableJsMounted = true;
        }

    },
    watch: {
        externalDataLoading: function (value) {
            if (!value) {
                this.getData();
            }
        },
        query: {
            deep: true,
            handler: function () {
                // if (this.pendingRequest)
                //     this.cancelTokenSource.cancel();
                //this.$emit('reload', { fullReload: false });
                this.$emit('queryChanged', this.query);
            },
        },
        currentRange: {
            deep: true,
            handler: function () {
                this.setExportLink();
            }
        }
    },
}
</script>
