<template>
    <td :data-id="item.product.nmId" data-title="Товар">
        <div class="item">
            <img class="item__img" v-if="groupBy === 'brand'" :src="item.product.image" onerror="this.src='/img/product1.jpg'" width="40" height="40" alt="product">
            <div class="item__body">
                <div class="item__data item__data_mob" v-if="groupBy === 'brand'" >
                    <span class="item__product-id" v-if="item.product.nmId">
                        <button class="copy-btn copy_button" :data-id="item.product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                        <a v-if="item.product.link" class="item__product-id__link" :href="item.product.link" target="_blank">{{ item.product.nmId }}</a>
                        <span v-else>{{ item.product.nmId }}</span>
                    </span>

                    <ProductTitle :title="item.product.title" :warning="true"/>

                    <div>{{ item.product.barcode }}</div>
                </div>

                <div class="item__data item__data_mob" v-else>
                    <div class="item__barcode">{{ item.product.barcode }}</div>
                    <span class="whitespace-nowrap">Цена: {{ item.product.price }}&nbsp;₽</span>
                    <span>{{
                            (item.product.tech_size != '' && item.product.tech_size != 0 && item.product.tech_size != 'null') ? ', размер: ' + item.product.tech_size : ''
                        }}</span>
                </div>

                <div v-if="groupBy === 'brand'" class="item__data item__data_desktop">
                    <ProductTitle :title="item.product.title" :warning="true"/>

                    <span v-if="item.product.nmId" class="item__product-id">
                        <button :data-id="item.product.nmId" class="copy-btn copy_button" data-bm-tooltip="Копировать"
                                type="button"><span class="fm-copy"></span></button>
                        <a v-if="item.product.link" :href="item.product.link" class="item__link" target="_blank">{{ item.product.nmId }}</a>
                        <span v-else>{{ item.product.nmId }}</span>
                    </span>&nbsp;/
                    <button class="show-info-btn" v-if="!show_info" @click="showMore" type="button">Подробнее <i
                        class="fm-chevron-right"></i></button>
                    <span v-if="item.product.supplier_article && show_info">{{ item.product.supplier_article }} / </span>
                    <span v-if="show_info">{{ item.product.barcode }}</span>

                    <div v-if="show_info">
                        <button :class="disableProductPrice ? 'disabled_link' :'open_price_popover'" :data-cost="item.product.cost"
                                :data-id="item.product.id" class="item__link">
                            Себест.: <span :id="'product-cost-'+item.product.id">{{
                                item.summary.costList.join(', ').replaceAll('.', ',')
                            }}</span>&nbsp;₽
                        </button>
                        <span v-if="item.product.discountedPrice > 0"> &nbsp;/
                            <span class="whitespace-nowrap">Цена: <Currency
                                :value="item.product.discountedPrice"/></span>
                        </span>
                    </div>
                    <div v-if="show_info" @click="showMore">
                        <Tippy
                            :content="'Полное количество: ' + item.product.quantityFull + '<br>Доступно для продажи: ' + item.product.quantity + '<br>В пути к клиенту: ' + item.product.inWayToClient + '<br>В пути от клиента: ' + item.product.inWayFromClient + '<br>'"
                            :allow-h-t-m-l="true"
                        >Остаток: {{ item.product.quantity ? item.product.quantity : 0 }}</Tippy>&nbsp;/
                        <button class="show-info-btn" data-product-hide-more type="button">Скрыть <i
                            class="fm-chevron-up"></i></button>
                    </div>
                </div>

                <div v-else class="item__data item__data_desktop">
                    <div>
                        <span class="item__barcode">{{ item.product.barcode }}, </span>

                        <button :class="disableProductPrice ? 'disabled_link' :'open_price_popover'" :data-cost="item.product.cost"
                                :data-id="item.product.id" class="item__link">
                            Себест.: <span :id="'product-cost-'+item.product.id">{{
                                (item.summary.costList.length) ? item.summary.costList.join(', ') : item.product.cost
                            }}</span>&nbsp;₽
                        </button>
                    </div>

                    <span v-if="item.product.discountedPrice > 0" class="whitespace-nowrap">Цена: <Currency
                        :value="item.product.discountedPrice"/></span>

                    <span>{{
                            (item.product.tech_size != '' && item.product.tech_size != 0 && item.product.tech_size != 'null') ? ', размер: ' + item.product.tech_size : ''
                        }}</span>
                </div>

            </div>
        </div>
    </td>
    <td>{{ item.summary.quantity }}</td>
    <td>{{ item.summary.refundQuantity }}</td>
    <td>
        <Currency :value="item.summary.realSalesAmount"/>
    </td>
    <td>
        <Currency :value="item.summary.refundsAmount"/>
    </td>
    <td>
        <Currency :value="item.summary.deduction"/>
    </td>
    <!-- <td><Currency :value="item.summary.logisticCost"/></td> -->
    <!-- <td><Currency :value="item.summary.commission"/></td> -->
    <!-- <td><Currency :value="item.summary.grossProfit"/></td> -->
    <!-- <td><Currency :value="item.summary.expenses"/></td> -->
    <td>
        <Currency :value="item.summary.profit"/>
    </td>
    <td>
        <Currency :value="item.summary.profitPerItem"/>
    </td>
    <!-- <td><Currency :value="item.summary.wbPay"/></td> -->
    <template v-if="item.summary.quantity">
        <td>
            <Percent :value="item.summary.margin"/>
        </td>
        <td>
            <Percent :value="item.summary.roi"/>
        </td>
        <template v-if="item.summary.stable">
            <td>
                <Percent :value="item.summary.redemption"/>
            </td>
        </template>
        <template v-else>
            <td>-</td>
        </template>
    </template>
    <template v-else>
        <td>-</td>
        <td>-</td>
        <td>-</td>
    </template>
    <!-- <td>{{ item.summary.countries.join(', ') }}</td> -->
    <td>
        <!-- <DashboardMoreButton v-bind:data="item.summary"/> -->
        <button aria-label="Больше" class="product-more-btn fm-chevron-right" type="button"
                @click="setSummary(item.summary, item.product)"></button>
    </td>
</template>

<script>

import Currency from '@/Components/Common/Currency.vue'
import DashboardMoreButton from '@/Components/Dashboard/DashboardMoreButton'
import { Link } from '@inertiajs/inertia-vue3'
import Percent from '@/Components/Common/Percent.vue'
import ProductTitle from '@/Components/Products/Title.vue'
import { Tippy } from 'vue-tippy'

export default {
    props: [
        'item',
        'disableProductPrice',
        'groupBy'
    ],
    components: {
        Tippy,
        ProductTitle,
        Percent,
        Currency,
        DashboardMoreButton,
        Link,
    },
    emits: ['setSummary'],

    data() {
        return {
            show_info: false,
        };
    },
    mounted() {


    },
    watch: {},
    methods: {
        setSummary(summary, product) {
            this.$emit('setSummary', summary, product);
        },
        showMore() {
            this.show_info = !this.show_info;
        }
    },
}
</script>
