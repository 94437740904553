<template>
    <td data-title="Товар" :data-id="item.product.id" data-product-cell>
        <div class="item">
            <img class="item__img" :src="item.product.image" onerror="this.src='/img/product1.jpg'" width="40" height="40" :alt="item.product.title">
            <div class="item__body">
                <div class="item__data item__data_mob">
                    <span class="item__product-id" v-if="item.product.nmId">
                        <button class="copy-btn copy_button" :data-id="item.product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                        <a v-if="item.product.link" class="item__product-id__link" :href="item.product.link" target="_blank">{{ item.product.nmId }}</a>
                        <span v-else>{{ item.product.nmId }}</span>
                    </span>

                    <ProductTitle :title="item.product.title" :warning="true"/>

                    <div>{{ item.product.barcode }}</div>
                </div>

                <div class="item__data item__data_desktop">
                    <ProductTitle :title="item.product.title" :warning="true"/>

                    <span class="item__product-id" v-if="item.product.nmId">
                        <button class="copy-btn copy_button" :data-id="item.product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                        <a v-if="item.product.link" class="item__link" :href="item.product.link" target="_blank">{{ item.product.nmId }}</a>
                        <span v-else>{{ item.product.nmId }}</span>
                    </span>&nbsp;/
                    <button class="show-info-btn" type="button" v-if="!show_info" @click="showMore">Подробнее <i class="fm-chevron-right"></i></button>
                    <span v-if="item.product.supplier_article && show_info" class="hidden">{{ item.product.supplier_article }} / </span>
                    <span v-if="show_info">{{ item.product.barcode }}</span>

                    <div v-if="show_info">
                        <button class="item__link" :class="disableProductPrice ? 'disabled_link' :'open_price_popover'" :data-id="item.product.id" :data-cost="item.product.cost">
                            Себест.: <span :id="'product-cost-'+item.product.id">{{ item.summary.costList.join(', ').replaceAll('.',',') }}</span>&nbsp;₽
                        </button>
                        <span v-if="item.product.discountedPrice > 0"> &nbsp;/
                            <span class="whitespace-nowrap">Цена: <Currency :value="item.product.discountedPrice"/></span>
                        </span>
                    </div>
                    <div v-if="show_info" @click="showMore">
                        <button
                            class="item__link"
                            v-tippy="'Полное количество: ' + item.product.quantityFull + '<br>Доступно для продажи: ' + item.product.quantity + '<br>В пути к клиенту: ' + item.product.inWayToClient + '<br>В пути от клиента: ' + item.product.inWayFromClient + '<br>'"
                        >Остаток: {{ item.product.quantity ? item.product.quantity : 0 }}</button>&nbsp;/ <button class="show-info-btn" type="button" data-product-hide-more>Скрыть <i class="fm-chevron-up"></i></button>
                    </div>
                </div>
            </div>
        </div>

        <!--
        <div class="product_wrapper hide-xs">
            <div class="product_image">
                <img class="product-thumbnail" :src="item.product.image" alt="product">
            </div>
            <div class="product_content">
                <div class="name" :title="item.product.title"><span>{{ item.product.title }}</span></div>
                <div v-if="item.product.nmId">
                    <button class="copy_button" :data-id="item.product.nmId" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                    <a :href="item.product.link" target="_blank">{{ item.product.nmId }}</a>&nbsp;/
                    <button class="show-info-btn" type="button" data-product-show-more>Подробнее <i class="fm-chevron-right"></i></button>
                    <span class="hidden" data-product-show-more-element>{{ item.product.barcode }}</span>
                </div>
                <div v-else>
                    {{ item.product.barcode }}
                </div>
                <div class="hidden" data-product-show-more-element>
                    <div class="popover_outer price_popover">
                        <a class="open_price_popover" :data-id="item.product.id" :data-cost="item.product.cost">
                            <span class="fm-edit"></span><span>Себест.: ₽ <span :id="'product-cost-'+item.product.id">{{ (item.summary.costList.length) ? item.summary.costList.join(', ') : item.product.cost }}</span></span>
                        </a>
                    </div>
                    <span v-if="item.product.price - item.product.discount > 0">
                        &nbsp;/ <span class="whitespace-nowrap">Цена: ₽&nbsp;{{ item.product.price - item.product.discount }}</span>
                    </span>

                </div>
                <div class="hidden" data-product-show-more-element>
                    <button v-if="item.product.quantity > 0" type="button"><span class="fm-cursor-hand"></span></button>
                    <a v-if="item.product.quantity > 0" data-toggle="tooltip" data-placement="bottom" data-html="true"  :data-title="'Полное количество: ' + item.product.quantityFull + '<br>Доступно для продажи: ' + item.product.quantity + '<br>Количество не в заказе: ' + item.product.quantityNotInOrders + '<br>В пути к клиенту: ' + item.product.inWayToClient + '<br>В пути от клиента: ' + item.product.inWayFromClient + '<br>'">Остаток: {{ item.product.quantity }}</a>
                    <span><span v-show="item.product.quantity > 0">&nbsp;/</span> <button class="show-info-btn" type="button" data-product-hide-more>Скрыть <i class="fm-chevron-up"></i></button></span>
                </div>
            </div>
        </div>
        -->
    </td>
    <td>{{ item.summary.quantity }}</td>
    <td>{{ item.summary.refundQuantity }}</td>
    <td><Currency :value="item.summary.realSalesAmount"/></td>
    <td><Currency :value="item.summary.refundsAmount"/></td>
    <td><Currency :value="item.summary.deduction"/></td>
    <!-- <td><Currency :value="item.summary.logisticCost"/></td> -->
    <!-- <td><Currency :value="item.summary.commission"/></td> -->
    <!-- <td><Currency :value="item.summary.grossProfit"/></td> -->
    <!-- <td><Currency :value="item.summary.expenses"/></td> -->
    <td><Currency :value="item.summary.profit"/></td>
    <td><Currency :value="item.summary.profitPerItem"/></td>
    <!-- <td><Currency :value="item.summary.wbPay"/></td> -->
    <template v-if="item.summary.quantity">
        <td><Percent :value="item.summary.margin"/></td>
        <td><Percent :value="item.summary.roi"/></td>
        <template v-if="item.summary.stable">
            <td><Percent :value="item.summary.redemption"/></td>
        </template>
        <template v-else>
            <td>-</td>
        </template>
    </template>
    <template v-else>
        <td>-</td>
        <td>-</td>
        <td>-</td>
    </template>
    <!-- <td>{{ item.summary.countries.join(', ') }}</td> -->
    <td>
        <!-- <DashboardMoreButton v-bind:data="item.summary"/> -->
        <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="setSummary(item.summary, item.product)"></button>
    </td>
</template>

<script>

import DashboardMoreButton from '@/Components/Dashboard/DashboardMoreButton'
import { Link } from '@inertiajs/inertia-vue3'
import Currency from '@/Components/Common/Currency.vue'
import Percent from '@/Components/Common/Percent.vue'
import ProductTitle from '@/Components/Products/Title.vue'

export default  {
    props: [
        'item','disableProductPrice'
    ],
    components: {
        ProductTitle,
        Percent,
        Currency,
        DashboardMoreButton,
        Link,
    },
    emits: ['setSummary'],

    data() {
        return {
            show_info: false,
        };
    },
    mounted() {
    },
    watch: {

    },
    methods: {
        setSummary(summary, product){
            this.$emit('setSummary', summary, product);
        },
        showMore() {
            this.show_info = !this.show_info;
        }

    },
}
</script>
