<template>
    <div>



        <div class="expenses-wrapper dashboard-wrapper" :class="{'no_items': (!isPeriodReady() || !data.data || data.data.length === 0) && !loading}">

            <div v-if="!isPeriodReady()" class="empty_banner-wrapper">
                <div class="empty_banner">
                    <div class="empty_banner_search empty_banner_transparent">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <h4 class="empty_banner_title">Данные за этот период еще загружаются</h4>
                    <p class="empty_banner_description">Попробуйте вернуться позже</p>
                </div>
            </div>
            <div v-else-if="loading" class="loading-wrapper">
                <div class="loading-wrapper__inner">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div v-else-if="(!data.data || data.data.length === 0) && !loading" class="empty_banner-wrapper">
                <div class="empty_banner">
                    <div class="empty_banner_search">
                        <span class="fm-carbon_search"></span>
                    </div>
                    <h4 class="empty_banner_title">Ничего не найдено</h4>
                    <p class="empty_banner_description">Попробуйте поменять параметры фильтра и попробовать снова</p>
                </div>
            </div>


            <table v-show="isPeriodReady() && data.data && data.data.length > 0 && !loading" id="dashboardOrdersTable" data-responsive-table class="table table-condensed table-hover table-fw-widget dashboard-table dashboard-table_orders" :class="groupOrdersBy">
                <thead v-show="groupOrdersBy === 'noGrouping'">
                    <tr>
                        <th style="width: 10%;min-width: 100px;">Заказ</th>
                        <th style="width: 20%;min-width: 100px;">Товар</th>
                        <th style="width: 5%; min-width: 50px;"
                            @click="sortByColumn('quantity')"
                            :class="{'reorder_outer': this.query.orderBy === 'quantity'}"
                            class="sortable-header"
                            data-breakpoints="xs sm md lg">
                            Заказано
                            <button v-show="this.query.orderBy === 'quantity'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md lg"
                            @click="sortByColumn('refunds')"
                            :class="{'reorder_outer': this.query.orderBy === 'refunds'}"
                            class="sortable-header">
                            Возвращено
                            <button v-show="this.query.orderBy === 'refunds'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('price')"
                            :class="{'reorder_outer': this.query.orderBy === 'price'}"
                            class="sortable-header">
                            Продажи
                            <button v-show="this.query.orderBy === 'price'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('refundsAmount')" class="sortable-header"
                            :class="{'reorder_outer': this.query.orderBy === 'refundsAmount'}" data-breakpoints="xs sm md lg">
                            Возвраты
                            <button v-show="this.query.orderBy === 'refundsAmount'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('deduction')" class="sortable-header nowrap"
                            :class="{'reorder_outer': this.query.orderBy === 'deduction'}" data-breakpoints="xs sm md lg">
                            Удержания WB
                            <button v-show="this.query.orderBy === 'deduction'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('logistic_cost')"
                            :class="{'reorder_outer': this.query.orderBy === 'logistic_cost'}"
                            class="sortable-header">
                            Доставка
                            <button v-show="this.query.orderBy === 'logistic_cost'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('commission')"
                            :class="{'reorder_outer': this.query.orderBy === 'commission'}"
                            class="sortable-header">
                            Комиссия
                            <button v-show="this.query.orderBy === 'commission'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('gross_profit')"
                            :class="{'reorder_outer': this.query.orderBy === 'gross_profit'}"
                            class="sortable-header nowrap">
                            <button v-show="inaccurate" class="tooltip_outer" type="button" :data-tippy-content="__('hints.dashboard.inaccurate')">
                                <span class="fm-icon-attention"></span>
                            </button>
                            Вал. прибыль
                            <button v-show="this.query.orderBy === 'gross_profit'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md lg"
                            @click="sortByColumn('expenses')"
                            :class="{'reorder_outer': this.query.orderBy === 'expenses'}"
                            class="sortable-header">
                            Расходы
                            <button v-show="this.query.orderBy === 'expenses'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs"
                            @click="sortByColumn('profit')"
                            :class="{'reorder_outer': this.query.orderBy === 'profit'}"
                            class="sortable-header nowrap">
                            <button v-show="inaccurate" class="tooltip_outer" type="button" :data-tippy-content="__('hints.dashboard.inaccurate')">
                                <span class="fm-icon-attention"></span>
                            </button>
                            Прибыль
                            <button v-show="this.query.orderBy === 'profit'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('profitPerItem')" class="sortable-header nowrap"
                            :class="{'reorder_outer': this.query.orderBy === 'profitPerItem'}"
                            data-breakpoints="xs sm md">
                            Прибыль на&nbsp;ед.
                            <button v-show="this.query.orderBy === 'profitPerItem'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button">
                            </button>
                        </th>
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('wb_pay')"
                            :class="{'reorder_outer': this.query.orderBy === 'wb_pay'}"
                            class="sortable-header">
                            Выплаты WB
                            <button v-show="this.query.orderBy === 'wb_pay'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('margin')"
                            :class="{'reorder_outer': this.query.orderBy === 'margin'}"
                            class="sortable-header">
                            Маржа
                            <button v-show="this.query.orderBy === 'margin'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('roi')"
                            :class="{'reorder_outer': this.query.orderBy === 'roi'}"
                            class="sortable-header">
                            ROI
                            <button v-show="this.query.orderBy === 'roi'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs"
                            @click="sortByColumn('sales')"
                            :class="{'reorder_outer': this.query.orderBy === 'sales'}"
                            class="sortable-header">
                            Статус
                            <button v-show="this.query.orderBy === 'sales'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs">
                            Страны
                        </th> -->
                        <th style="width: 5%; max-width: 60px;"><span class="hide-xs">Инфо</span></th>
                    </tr>
                </thead>
                <thead v-show="groupOrdersBy === 'byOrders'">
                    <tr>
                        <th style="width: 20%;min-width: 100px;">Заказы</th>
                        <th style="width: 5%; min-width: 50px;"
                            @click="sortByColumn('quantity')"
                            :class="{'reorder_outer': this.query.orderBy === 'quantity'}"
                            class="sortable-header"
                            data-breakpoints="xs sm md lg">
                            Заказано
                            <button v-show="this.query.orderBy === 'quantity'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md lg"
                            @click="sortByColumn('refunds')"
                            :class="{'reorder_outer': this.query.orderBy === 'refunds'}"
                            class="sortable-header">
                            Возвращено
                            <button v-show="this.query.orderBy === 'refunds'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('price')"
                            :class="{'reorder_outer': this.query.orderBy === 'price'}"
                            class="sortable-header">
                            Продажи
                            <button v-show="this.query.orderBy === 'price'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('refundsAmount')" class="sortable-header"
                            :class="{'reorder_outer': this.query.orderBy === 'refundsAmount'}" data-breakpoints="xs sm md lg">
                            Возвраты
                            <button v-show="this.query.orderBy === 'refundsAmount'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('deduction')" class="sortable-header nowrap"
                            :class="{'reorder_outer': this.query.orderBy === 'deduction'}" data-breakpoints="xs sm md lg">
                            Удержания WB
                            <button v-show="this.query.orderBy === 'deduction'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('logistic_cost')"
                            :class="{'reorder_outer': this.query.orderBy === 'logistic_cost'}"
                            class="sortable-header">
                            Доставка
                            <button v-show="this.query.orderBy === 'logistic_cost'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('commission')"
                            :class="{'reorder_outer': this.query.orderBy === 'commission'}"
                            class="sortable-header">
                            Комиссия
                            <button v-show="this.query.orderBy === 'commission'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('gross_profit')"
                            :class="{'reorder_outer': this.query.orderBy === 'gross_profit'}"
                            class="sortable-header nowrap">
                            <button v-show="inaccurate" class="tooltip_outer" type="button" :data-tippy-content="__('hints.dashboard.inaccurate')">
                                <span class="fm-icon-attention"></span>
                            </button>
                            Вал. прибыль
                            <button v-show="this.query.orderBy === 'gross_profit'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md lg"
                            @click="sortByColumn('expenses')"
                            :class="{'reorder_outer': this.query.orderBy === 'expenses'}"
                            class="sortable-header">
                            Расходы
                            <button v-show="this.query.orderBy === 'expenses'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs"
                            @click="sortByColumn('profit')"
                            :class="{'reorder_outer': this.query.orderBy === 'profit'}"
                            class="sortable-header nowrap">
                            <button v-show="inaccurate" class="tooltip_outer" type="button" :data-tippy-content="__('hints.dashboard.inaccurate')">
                                <span class="fm-icon-attention"></span>
                            </button>
                            Прибыль
                            <button v-show="this.query.orderBy === 'profit'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('profitPerItem')" class="sortable-header nowrap"
                            :class="{'reorder_outer': this.query.orderBy === 'profitPerItem'}"
                            data-breakpoints="xs sm md">
                            Прибыль на&nbsp;ед.
                            <button v-show="this.query.orderBy === 'profitPerItem'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button">
                            </button>
                        </th>
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('wb_pay')"
                            :class="{'reorder_outer': this.query.orderBy === 'wb_pay'}"
                            class="sortable-header">
                            Выплаты WB
                            <button v-show="this.query.orderBy === 'wb_pay'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th> -->
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('margin')"
                            :class="{'reorder_outer': this.query.orderBy === 'margin'}"
                            class="sortable-header">
                            Маржа
                            <button v-show="this.query.orderBy === 'margin'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md"
                            @click="sortByColumn('roi')"
                            :class="{'reorder_outer': this.query.orderBy === 'roi'}"
                            class="sortable-header">
                            ROI
                            <button v-show="this.query.orderBy === 'roi'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs"
                            @click="sortByColumn('sales')"
                            :class="{'reorder_outer': this.query.orderBy === 'sales'}"
                            class="sortable-header">
                            Статус
                            <button v-show="this.query.orderBy === 'sales'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <!-- <th style="width: 5%; min-width: 50px;" data-breakpoints="xs">
                            Страны
                        </th> -->
                        <th style="width: 5%; max-width: 60px;"><span class="hide-xs">Инфо</span></th>
                    </tr>
                </thead>
                <thead v-show="groupOrdersBy === 'bySku'">
                    <tr>
                        <th style="width: 20%;min-width: 100px;">Товар</th>
                        <th style="width: 5%; min-width: 50px;"
                            @click="sortByColumn('quantity')"
                            :class="{'reorder_outer': this.query.orderBy === 'quantity'}"
                            class="sortable-header"
                            data-breakpoints="xs sm md lg">
                            Заказано
                            <button v-show="this.query.orderBy === 'quantity'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm md lg"
                            @click="sortByColumn('refunds')"
                            :class="{'reorder_outer': this.query.orderBy === 'refunds'}"
                            class="sortable-header">
                            Возвращено
                            <button v-show="this.query.orderBy === 'refunds'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs sm"
                            @click="sortByColumn('price')"
                            :class="{'reorder_outer': this.query.orderBy === 'price'}"
                            class="sortable-header">
                            Продажи
                            <button v-show="this.query.orderBy === 'price'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('refundsAmount')" class="sortable-header"
                            :class="{'reorder_outer': this.query.orderBy === 'refundsAmount'}" data-breakpoints="xs sm md lg">
                            Возвраты
                            <button v-show="this.query.orderBy === 'refundsAmount'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('deduction')" class="sortable-header nowrap"
                            :class="{'reorder_outer': this.query.orderBy === 'deduction'}" data-breakpoints="xs sm md lg">
                            Удержания WB
                            <button v-show="this.query.orderBy === 'deduction'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" data-breakpoints="xs"
                            @click="sortByColumn('profit')"
                            :class="{'reorder_outer': this.query.orderBy === 'profit'}"
                            class="sortable-header nowrap">
                            <button v-show="inaccurate" class="tooltip_outer" type="button" :data-tippy-content="__('hints.dashboard.inaccurate')">
                                <span class="fm-icon-attention"></span>
                            </button>
                            Прибыль
                            <button v-show="this.query.orderBy === 'profit'" :class="{active: this.query.desc === 0}"
                                    type="button"
                                    class="reorder_button"></button>
                        </th>
                        <th style="width: 5%; min-width: 50px;" @click="sortByColumn('profitPerItem')" class="sortable-header nowrap"
                            :class="{'reorder_outer': this.query.orderBy === 'profitPerItem'}"
                            data-breakpoints="xs sm md">
                            Прибыль на&nbsp;ед.
                            <button v-show="this.query.orderBy === 'profitPerItem'" :class="{active: this.query.desc === 0}"
                                type="button"
                                class="reorder_button">
                            </button>
                        </th>
                        <th style="width: 5%; max-width: 60px;"><span class="hide-xs">Инфо</span></th>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="groupOrdersBy === 'noGrouping'" v-for="(item) in data.data">
                        <tr v-for="(product) in item.products"
                             :data-order_id="item.g_number">
                             <td data-title="Заказ">
                                <div class="item">
                                    <div class="item__body">
                                        <div class="item__data">
                                            <span class="item__order nowrap">
                                                <button class="copy-btn copy_button" :data-id="item.g_number+'_'" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                <span class="item__order__title">{{ item.g_number }}</span>
                                            </span>

                                            <div>{{ item.date }}</div>
                                        </div>
                                    </div>
                                    <!--
                                    <span v-if="item.summary.sales === 0" class="status-dot status-dot_dimmed"></span>
                                    <span v-else-if="item.summary.sales === item.summary.quantity" class="status-dot status-dot_success"></span>
                                    <span v-else-if="item.summary.sales < item.summary.quantity" class="status-dot status-dot_partial"></span>
                                    -->
                                </div>
                            </td>
                            <td data-title="Товар">
                                <div class="item">
                                    <img class="item__img" :src="product.image" width="40" height="40" alt="product">
                                    <div class="item__body">
                                        <div class="item__data">
                                            <span class="item__product-id">
                                                <button class="copy-btn copy_button" :data-id="product.nm_id" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                <a v-if="product.link" class="item__product-id__link" :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                <span v-else>{{ product.nm_id }}</span>
                                            </span>

                                            <ProductTitle :title="product.title" :warning="true"/>
                                            <div>{{ product.barcode }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{ product.quantity }}</td>
                            <td>{{ product.summary.refunds }}</td>
                            <!-- <td><Currency :value="product.total.toFixed(2)"/></td> -->
                            <td><Currency :value="product.total"/></td>
                            <td><Currency :value="product.summary.refundsAmount"/></td>
                            <td><Currency :value="product.summary.deduction"/></td>
                            <!-- <td><Currency :value="product.summary.logisticCost"/></td> -->
                            <!-- <td><Currency :value="product.summary.commission"/></td> -->
                            <!-- <td><Currency :value="product.summary.grossProfit"/></td> -->
                            <!-- <td><Currency :value="product.summary.expenses"/></td> -->
                            <td><Currency :value="product.summary.profit"/></td>
                            <td><Currency :value="product.summary.profitPerItem"/></td>
                            <!-- <td><Currency :value="product.summary.wbPay"/></td> -->

                            <template v-if="product.summary.quantity">
                                <td><Percent :value="product.summary.margin"/></td>
                                <td><Percent :value="product.summary.roi"/></td>
                            </template>
                            <template v-else>
                                <td>-</td>
                                <td>-</td>
                            </template>
                            <td>
                                <span v-if="product.summary.sales === 0" class="status-tag status-tag_dimmed status-tag_responsive" v-tippy="'Не выкуплен'">Не выкуплен</span>
                                <span v-else-if="product.summary.sales === product.summary.quantity" class="status-tag status-tag_success status-tag_responsive" v-tippy="'Выкуплен'">Выкуплен</span>
                                <span v-else-if="product.summary.sales < product.summary.quantity" class="status-tag status-tag_partial status-tag_responsive" v-tippy="'Не полностью'">Не полностью</span>
                                <span v-else>-</span>
                            </td>
                            <!-- <td>{{ product.summary.countries.join(', ') }}</td> -->
                            <td>
                                <!-- <DashboardMoreButton v-bind:data="product.summary" v-bind:section="section"/> -->
                                <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="setSummary(product.summary, product)"></button>
                            </td>
                        </tr>
                    </template>
                    <template v-if="groupOrdersBy === 'byOrders'" v-for="(item) in data.data">
                        <tr class="products-grouped">
                            <td data-title="Заказ">
                                <div class="item">
                                    <button class="table-accordion__trigger" type="button" aria-label="Раскрыть/скрыть" data-toggle-table-row :data-order="item.g_number" aria-selected="false"></button>
                                    <div class="item__body">
                                        <div class="item__data">
                                            <span class="item__order nowrap">
                                                <button class="copy-btn copy_button" :data-id="item.g_number+'_'" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                <span class="item__order__title">{{ item.g_number }}</span>
                                            </span>

                                            <div>{{ item.date }}</div>
                                        </div>
                                    </div>
                                    <!--
                                    <span v-if="item.summary.sales === 0" class="status-dot status-dot_dimmed"></span>
                                    <span v-else-if="item.summary.sales === item.quantity" class="status-dot status-dot_success"></span>
                                    <span v-else-if="item.summary.sales < item.quantity" class="status-dot status-dot_partial"></span>
                                    -->
                                </div>
                            </td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.summary.refundQuantity }}</td>
                            <!-- <td><Currency :value="item.total"/></td> -->
                            <td><Currency :value="item.total"/></td>
                            <td><Currency :value="item.summary.refundsAmount"/></td>
                            <td><Currency :value="item.summary.deduction"/></td>
                            <!-- <td><Currency :value="item.summary.logisticCost"/></td> -->
                            <!-- <td><Currency :value="item.summary.commission"/></td> -->
                            <!-- <td><Currency :value="item.summary.grossProfit"/></td> -->
                            <!-- <td><Currency :value="item.summary.expenses"/></td> -->
                            <td><Currency :value="item.summary.profit"/></td>
                            <td><Currency :value="item.summary.profitPerItem"/></td>
                            <!-- <td><Currency :value="item.summary.wbPay"/></td> -->

                            <template v-if="item.summary.quantity">
                                <td><Percent :value="item.summary.margin"/></td>
                                <td><Percent :value="item.summary.roi"/></td>
                            </template>
                            <template v-else>
                                <td>-</td>
                                <td>-</td>
                            </template>
                            <td>
                                <span v-if="item.summary.sales === 0" class="status-tag status-tag_dimmed status-tag_responsive" v-tippy="'Не выкуплен'">Не выкуплен</span>
                                <span v-else-if="item.summary.sales === item.quantity" class="status-tag status-tag_success status-tag_responsive" v-tippy="'Выкуплен'">Выкуплен</span>
                                <span v-else-if="item.summary.sales < item.quantity" class="status-tag status-tag_partial status-tag_responsive" v-tippy="'Не полностью'">Не полностью</span>
                                <span v-else>-</span>
                            </td>
                            <!-- <td>{{ item.summary.countries.join(', ') }}</td> -->
                            <td>
                                <!-- <DashboardMoreButton v-bind:data="item.summary" v-bind:section="section"/> -->
                                <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="setSummary(item.summary, item)"></button>
                            </td>
                        </tr>

                        <tr v-for="(product) in item.products"
                            class="table-accordion__content hidden" :data-order_id="item.g_number">
                            <td data-title="Товар">
                                <div class="item">
                                    <img class="item__img" :src="product.image" width="40" height="40" alt="product">
                                    <div class="item__body">
                                        <div class="item__data item__data_mob item__data_orders">
                                            <ProductTitle :title="product.title" :warning="true"/>
                                        </div>

                                        <div class="item__data item__data_desktop">
                                            <ProductTitle :title="product.title" :warning="true"/>
                                            <span class="item__product-id">
                                                <button class="copy-btn copy_button" :data-id="product.nm_id" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                <a v-if="product.link" class="item__product-id__link" :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                <span v-else>{{ product.nm_id }}</span>
                                                <span v-if="product.supplier_article">&nbsp;/ {{ product.supplier_article }}</span>
                                                <span> / {{ product.barcode }}</span>
                                            </span>
                                            <div v-if="product.total > 0" class="whitespace-nowrap">Цена: <Currency :value="product.total"/></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{ product.quantity }}</td>
                            <td>{{ product.summary.refundQuantity }}</td>
                            <td><Currency :value="product.total"/></td>
                            <td><Currency :value="product.summary.refundsAmount"/></td>
                            <td><Currency :value="product.summary.deduction"/></td>
                            <!-- <td><Currency :value="product.total"/></td> -->
                            <!-- <td><Currency :value="product.summary.logisticCost"/></td> -->
                            <!-- <td><Currency :value="product.summary.commission"/></td> -->
                            <!-- <td><Currency :value="product.summary.grossProfit"/></td> -->
                            <!-- <td><Currency :value="product.summary.expenses"/></td> -->
                            <td><Currency :value="product.summary.profit"/></td>
                            <td><Currency :value="product.summary.profitPerItem"/></td>
                            <!-- <td><Currency :value="product.summary.wbPay"/></td> -->

                            <template v-if="product.summary.quantity">
                                <td><Percent :value="product.summary.margin"/></td>
                                <td><Percent :value="product.summary.roi"/></td>
                            </template>
                            <template v-else>
                                <td>-</td>
                                <td>-</td>
                            </template>
                            <td>
                                <span v-if="product.summary.sales === 0 && product.summary.refunds > 0" class="status-tag status-tag_danger status-tag_responsive" v-tippy="'Отменён'">Отменён</span>
                                <span v-else-if="product.summary.sales === 0" class="status-tag status-tag_dimmed status-tag_responsive" v-tippy="'Не выкуплен'">Не выкуплен</span>
                                <span v-else-if="product.summary.sales === product.summary.quantity" class="status-tag status-tag_success status-tag_responsive" v-tippy="'Выкуплен'">Выкуплен</span>
                                <span v-else>-</span>
                            </td>
                            <!-- <td>{{ product.summary.countries.join(', ') }}</td> -->
                            <td>
                                <!-- <DashboardMoreButton v-bind:data="product.summary" v-bind:section="section"/> -->
                                <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="setSummary(product.summary, product)"></button>
                            </td>
                        </tr>
                    </template>
                    <template v-if="groupOrdersBy === 'bySku'" v-for="(product) in data.data">
                        <tr class="products-grouped"
                             :data-product_id="product.id">
                            <td data-title="Товар">
                                <div class="item">
                                    <button class="table-accordion__trigger" type="button" aria-label="Раскрыть/скрыть" data-toggle-table-row :data-order="product.id" aria-selected="false"></button>
                                    <img class="item__img" :src="product.image" width="40" height="40" alt="product">
                                    <div class="item__body">
                                        <div class="item__data">
                                            <span class="item__product-id">
                                                <button class="copy-btn copy_button" :data-id="product.nm_id" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                <a v-if="product.link" class="item__product-id__link" :href="product.link" target="_blank">{{ product.nm_id }}</a>
                                                <span v-else>{{ product.nm_id }}</span>
                                            </span>

                                            <ProductTitle :title="product.title" :warning="true"/>
                                            <div>{{ product.barcode }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{ product.quantity }}</td>
                            <td>{{ product.summary.refundQuantity }}</td>

                            <td><Currency :value="product.total"/></td>
                            <td><Currency :value="product.summary.refundsAmount"/></td>
                            <td><Currency :value="product.summary.deduction"/></td>

                            <td><Currency :value="product.summary.profit"/></td>
                            <td><Currency :value="product.summary.profitPerItem"/></td>

                            <td>

                                <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="setSummary(product.summary, product.product)"></button>
                            </td>
                        </tr>
                        <tr v-for="(order) in product.orders"
                            class="table-accordion__content hidden"
                            :data-order_id="product.id">
                            <td data-title="Заказ">
                                <div class="item">
                                    <div class="item__body">
                                        <div class="item__data">
                                            <span class="item__order nowrap">
                                                <button class="copy-btn copy_button" :data-id="order.g_number+'_'" type="button" data-bm-tooltip="Копировать"><span class="fm-copy"></span></button>
                                                <span class="item__order__title">{{ order.g_number }}</span>
                                                <span v-if="order.summary.sales === 0"> / <span>Не выкуплен</span></span>
                                                <span v-else-if="order.summary.sales === order.summary.quantity"> / <span>Выкуплен</span></span>
                                            </span>

                                            <div>{{ order.date }}</div>
                                        </div>
                                    </div>
                                    <!--
                                    <span v-if="order.summary.sales === 0" class="status-dot status-dot_dimmed"></span>
                                    <span v-else-if="order.summary.sales === order.quantity" class="status-dot status-dot_success"></span>
                                    <span v-else-if="order.summary.sales < order.summary.quantity" class="status-dot status-dot_partial"></span>
                                    -->
                                </div>
                            </td>

                            <td>{{ order.quantity }}</td>
                            <td>{{ order.summary.refundQuantity }}</td>
                            <td><Currency :value="order.total"/></td>
                            <td><Currency :value="order.summary.refundsAmount"/></td>
                            <td><Currency :value="order.summary.deduction"/></td>

                            <td><Currency :value="order.summary.profit"/></td>
                            <td><Currency :value="order.summary.profitPerItem"/></td>
                            <td>
                                <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="setSummary(order.summary, order)"></button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div v-if="isPeriodReady() && data.data && data.data.length > 0 && !loading" class="table-last-section">
            <Pagination
                :current-page="data.meta.current_page"
                :total-pages="data.meta.last_page"
                :next="data.links.next"
                :previous="data.links.prev"
                :links="data.meta.links"
                @page-selected="pageChanged"
            />
        </div>

    </div>

</template>

<script>


import Pagination from '@/Components/Common/Pagination'
import DashboardMoreButton from '@/Components/Dashboard/DashboardMoreButton'
import { Link } from '@inertiajs/inertia-vue3'
import { pickBy } from 'lodash-es'
import Currency from '@/Components/Common/Currency.vue'
import Percent from '@/Components/Common/Percent.vue'
import ProductTitle from '@/Components/Products/Title.vue'

export default {
    props: [
        //'data',
        'currentRange',
        'currentMonthRange',
        'query',
        'externalDataLoading',
        'section',
        'groupByArticul',
        'groupOrdersBy',
        'forAll'
    ],
    components: {
        ProductTitle,
        Percent,
        Currency,
        DashboardMoreButton,
        Pagination,
        Link,
    },
    emits: ['reload', 'dashboardCostEdit', 'queryChanged', 'setSummary'],
    methods: {
        isPeriodReady(){
            let res = true;
            if (this.$page.props.auth.data.orders_updating && this.$page.props.auth.data.orders_updating_first_month)
                res = true;
            else
                res = this.$page.props.auth.data.orders_updating && !this.__isInRange(this.currentRange, this.currentMonthRange) && !this.$page.props.auth.data.orders_updating_first_month;
            return !res;
        },
        setSummary(summary, product){
            this.$emit('setSummary', summary, product);
        },
        sortByColumn: function (column) {

            if (this.query.orderBy === column)
                this.query.desc = this.query.desc === 1 ? 0 : 1;
            else
                this.query.desc = 1;

            this.query.orderBy = column;
            this.$emit('reload', { fullReload: true });
        },
        pageChanged(page){
            this.query.page = page;
            this.getData();
        },
        toggleTableRow(document, event) {
            var $element = $(event.target);
            var id = $element.data('order');

            if ($element.attr('aria-selected') == 'false') {
                $('[data-order_id="' + id + '"]').removeClass('hidden');
                $element.attr('aria-selected', 'true');
            } else {
                $('[data-order_id="' + id + '"]').addClass('hidden');
                $element.attr('aria-selected', 'false');
            }
        },

        getData() {
            let self = this;
            this.loading = true;

            $('#dashboardOrdersTable').hide();

            this.cancelTokenSource = axios.CancelToken.source();
            this.pendingRequest = true;
            this.inaccurate = false;
            let url = '/dashboard/orders';
            if(this.forAll){
                url = '/dashboard/orders-for-all';
            }
            axios.get(url, {
                cancelToken: this.cancelTokenSource.token,
                params: pickBy(this.query)
            }).then((result) => {
                this.data = result.data;

                this.data.data.forEach((item) => {
                    if (item.summary.inaccurate)
                        this.inaccurate = true;
                });

                setTimeout(function(){
                    self.responsiveTable();
                }, 200)

            });
        },
        tippyTooltips() {
            tippy('[data-tippy-content]', {
                trigger: 'mouseenter focus',
                placement: 'bottom',
                arrow: false
            });
        },
        responsiveTable() {
            let responsiveOrdersTable = $('#dashboardOrdersTable');

            if (!this.data.data.length) {
                responsiveOrdersTable.hide();
                this.loading = false;
                this.pendingRequest = false;
                return false;
            }

            this.loading = false;
            this.pendingRequest = false;
            this.tippyTooltips();
            // responsiveOrdersTable.footable({
            //     "showToggle": true
            // }, () => {
            //     //this.currencyInput();
            //
            //     this.loading = false;
            //     this.pendingRequest = false;
            //
            //     //this.tippyTooltips();
            // });
            //
            // responsiveOrdersTable.trigger('footable_initialize');

            setTimeout(() => {
                $('#tableDataLoader').fadeOut();
            }, 5000);
        }
    },
    data() {
        return {
            data: {
                data: [],
                meta: {
                    links: []
                },
                links: []
            },
            products: [],
            sort: {
                orderBy: 'quantity',
                desc: 1
            },
            loading: true,
            cancelTokenSource: null,
            pendingRequest: false,
            inaccurate: false,
        };
    },
    mounted() {
        if (this.section === 'orders') {
            this.getData();
        }
    },
    watch: {
        externalDataLoading: function (value) {
            if (!value) {
                this.getData();
            }
        },
        query: {
            deep: true,
            handler: function() {
                this.$emit('queryChanged', this.query);
            },
        }
    },

}
</script>
